var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"associatedParking"}},[_c('div',{staticClass:"table-container"},[_c('div',{staticClass:"table-filtrate"},[_c('ul'),_c('ul',[_c('li',[(
              _vm.buttonList.includes(
                'parkingLotList-detail-associatedParkingTab-add'
              )
            )?_c('el-button',{staticClass:"addBtn",attrs:{"type":"primary"},on:{"click":_vm.addBtn}},[_c('i',{staticClass:"iconfont icon-jiahao"}),_vm._v(" 新增")]):_vm._e()],1)])]),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.table.loading),expression:"table.loading"}],staticStyle:{"width":"100%"},attrs:{"data":_vm.table.tableData}},[_c('el-table-column',{attrs:{"prop":"serverName","label":"服务器名称","align":"center"}}),_c('el-table-column',{attrs:{"prop":"serverIp","label":"服务器IP","align":"center"}}),_c('el-table-column',{attrs:{"prop":"parkingLotCode","label":"ICC停车场编号","align":"center"}}),_c('el-table-column',{attrs:{"prop":"address","label":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
              _vm.buttonList.includes(
                'parkingLotList-detail-associatedParkingTab-edit'
              )
            )?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.editCorrelation(scope.row)}}},[_vm._v("编辑 ")]):_vm._e(),(
              _vm.buttonList.includes(
                'parkingLotList-detail-associatedParkingTab-delete'
              )
            )?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.delCorrelation(scope.row.parkingLotServerId)}}},[_vm._v("删除 ")]):_vm._e()]}}])})],1)],1),_c('el-dialog',{staticClass:"correlationDialog",attrs:{"title":"操作","visible":_vm.correlationDialog.show,"close-on-press-escape":false},on:{"update:visible":function($event){return _vm.$set(_vm.correlationDialog, "show", $event)},"closed":_vm.correlationDialog_reset}},[_c('el-form',{ref:"correlationDialog",attrs:{"model":_vm.correlationDialog,"rules":_vm.correlationDialog.rules,"label-width":"100px"}},[_c('el-form-item',{attrs:{"label":"停车场编号","prop":"parkingLotCode"}},[_c('el-input',{attrs:{"placeholder":"请输入停车场编号"},model:{value:(_vm.correlationDialog.parkingLotCode),callback:function ($$v) {_vm.$set(_vm.correlationDialog, "parkingLotCode", $$v)},expression:"correlationDialog.parkingLotCode"}})],1),_c('el-form-item',{attrs:{"label":"服务器","prop":"server"}},[_c('el-select',{attrs:{"placeholder":"请选择服务器"},model:{value:(_vm.correlationDialog.serverId),callback:function ($$v) {_vm.$set(_vm.correlationDialog, "serverId", $$v)},expression:"correlationDialog.serverId"}},_vm._l((_vm.serverList),function(item){return _c('el-option',{key:item.serverId,attrs:{"label":item.serverName,"value":item.serverId}})}),1)],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"size":"medium"},on:{"click":function($event){_vm.correlationDialog.show = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary","size":"medium"},on:{"click":_vm.correlationSave}},[_vm._v("确 定")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }