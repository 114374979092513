<template>
  <div id="associatedParking">
    <div class="table-container">
      <div class="table-filtrate">
        <ul></ul>
        <ul>
          <li>
            <el-button
                class="addBtn"
                type="primary"
                @click="addBtn"
                v-if="
                buttonList.includes(
                  'parkingLotList-detail-associatedParkingTab-add'
                )
              "
            ><i class="iconfont icon-jiahao"></i> 新增</el-button
            >
          </li>
        </ul>
      </div>
      <el-table
          :data="table.tableData"
          style="width: 100%"
          v-loading="table.loading"
      >
        <el-table-column prop="serverName" label="服务器名称" align="center">
        </el-table-column>
        <el-table-column prop="serverIp" label="服务器IP" align="center">
        </el-table-column>
        <el-table-column
            prop="parkingLotCode"
            label="ICC停车场编号"
            align="center"
        >
        </el-table-column>
        <el-table-column prop="address" label="操作" align="center">
          <template slot-scope="scope">
            <el-button
                type="text"
                @click="editCorrelation(scope.row)"
                v-if="
                buttonList.includes(
                  'parkingLotList-detail-associatedParkingTab-edit'
                )
              "
            >编辑
            </el-button
            >
            <el-button
                type="text"
                @click="delCorrelation(scope.row.parkingLotServerId)"
                v-if="
                buttonList.includes(
                  'parkingLotList-detail-associatedParkingTab-delete'
                )
              "
            >删除
            </el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
        class="correlationDialog"
        title="操作"
        :visible.sync="correlationDialog.show"
        :close-on-press-escape="false"
        @closed="correlationDialog_reset"
    >
      <el-form
          :model="correlationDialog"
          :rules="correlationDialog.rules"
          ref="correlationDialog"
          label-width="100px"
      >
        <el-form-item label="停车场编号" prop="parkingLotCode">
          <el-input
              v-model="correlationDialog.parkingLotCode"
              placeholder="请输入停车场编号"
          ></el-input>
        </el-form-item>
        <el-form-item label="服务器" prop="server">
          <el-select
              v-model="correlationDialog.serverId"
              placeholder="请选择服务器"
          >
            <el-option
                v-for="item of serverList"
                :key="item.serverId"
                :label="item.serverName"
                :value="item.serverId"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="correlationDialog.show = false"
        >取 消</el-button
        >
        <el-button type="primary" size="medium" @click="correlationSave"
        >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      table: {
        tableData: [],
        loading: false,
      },
      serverList:[],
      parkingLotServerId:"",
      correlationDialog: {
        show: false,
        parkingLotCode: "",
        serverId: "",
        rules: {
          parkingLotCode: [
            { required: true, message: "请输入停车场编号", trigger: "blur" },
          ],
          serverId: [
            { required: true, message: "请选择服务器", trigger: "change" },
          ],
        },
      },
    };
  },
  created() {
    this.getList();
    this.getServeList()
  },
  methods: {
    correlationDialog_reset() {
      this.parkingLotServerId = "";
      this.correlationDialog.parkingLotCode = "";
      this.correlationDialog.serverId = "";
    },
    async getServeList() {
      try {
        let res = await this.$http.get("/server/list/all");
        if (res.code === 0) {
          for (let item of res.data) {
            item.serverName = `${item.serverName}（${item.serverIp}）`;
          }
          this.serverList = res.data;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      }
    },
    addBtn(){
      this.correlationDialog.show = true;
    },
    async correlationSave(){
      let falg = true;
      this.$refs["correlationDialog"].validate((valid) => {
        if (!valid) {
          falg = false;
        }
      });
      if (!falg) {
        return;
      }
      try {
        let url = `/parking/connect/add`
        let params = {
          parkingLotCode: this.correlationDialog.parkingLotCode,
          serverId: this.correlationDialog.serverId,
          parkingLotId:this.$route.params.id
        }
        if(this.parkingLotServerId){
          url = `/parking/connect/edit`
          params={
            parkingLotCode: this.correlationDialog.parkingLotCode,
            serverId: this.correlationDialog.serverId,
            parkingLotServerId:this.parkingLotServerId
          }
        }
        let res = await this.$http.post(url,params);
        if (res.code === 0) {
          this.$message.success("操作成功");
          this.correlationDialog.show = false;
          this.getList();
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      }
    },
    async editCorrelation(row){

      this.parkingLotServerId = row.parkingLotServerId;
      Object.assign(this.correlationDialog,{
        show: true,
        parkingLotCode: row.parkingLotCode,
        serverId: row.serverId,
      })
    },
    delCorrelation(parkingLotServerId) {
      this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(async () => {
            let res = await this.$http.delete(
                `/parking/connect/${parkingLotServerId}/delete`
            );
            if (res.code === 0) {
              this.$message.success("删除成功");
              this.getList();
            } else {
              this.$message.error(res.msg);
            }
          })
          .catch(() => {});
    },
    async getList(page = this.table.page) {
      this.table.page=page
      try {
        this.table.loading = true;
        let res = await this.$http.get(`/parking/connect/${this.$route.params.id}/list`);
        if (res.code === 0) {
          Object.assign(this.table, {
            tableData: res.data,
          });
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.table.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
#associatedParking {
  .addBtn {
    background-color: #ff9747;
    border-color: #ff9747;
    i {
      font-size: 14px;
    }
  }
  .correlationDialog {
      .el-dialog {
        width: 500px;
        .el-select{
          width: 100%;
        }
      }
  }
}
</style>
